// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    home: path(ROOTS_DASHBOARD, "/home"),
    profile: path(ROOTS_DASHBOARD, "/profile"),
    orgProfile: path(ROOTS_DASHBOARD, "/org-profile"),
  },
  organisation: {
    root: path(ROOTS_DASHBOARD, "/organisation"),
    units: path(ROOTS_DASHBOARD, "/organisation/units"),
    users: {
      root: path(ROOTS_DASHBOARD, "/organisation/users"),
      list: path(ROOTS_DASHBOARD, "/organisation/users/list"),
      create: path(ROOTS_DASHBOARD, "/organisation/users/create-user"),
      profile: path(ROOTS_DASHBOARD, "/users/profile"),
      edit: (name) => path(ROOTS_DASHBOARD, `/organisation/users/${name}/edit`),
    },
    customFields: path(ROOTS_DASHBOARD, "/organisation/custom-fields"),
  },

  socialPost: {
    root: path(ROOTS_DASHBOARD, "/social-post"),
    newPost: path(ROOTS_DASHBOARD, "/social-post/new-post"),
    allPosts: path(ROOTS_DASHBOARD, "/social-post/all-posts"),
    analytics: path(ROOTS_DASHBOARD, "/social-post/analytics"),
    postDetails: (name, id) =>
      path(ROOTS_DASHBOARD, `/social-post/analytics/${name}/${id}`),
    postLinks: path(ROOTS_DASHBOARD, "/social-post/all-posts/post-links"),
    facebookAndInstagram: path(ROOTS_DASHBOARD, "/social-post/settings"),
  },

  chatApp: {
    settings: path(ROOTS_DASHBOARD, "/chat-app-settings"),
    chatReport: path(ROOTS_DASHBOARD, "/chat-app/report"),
    bot: path(ROOTS_DASHBOARD, "/chat-app/bot"),
    createTrigger: path(ROOTS_DASHBOARD, "/chat-app/bot/create-trigger"),
    editTrigger: path(ROOTS_DASHBOARD, "/chat-app/bot/edit-trigger"),
  },

  whatsapp: {
    root: path(ROOTS_DASHBOARD, "/whatsapp"),
    settings: path(ROOTS_DASHBOARD, "/whatsapp/settings"),
    generalSettings: path(ROOTS_DASHBOARD, "/whatsapp/settings/general"),
    reportSettings: path(ROOTS_DASHBOARD, "/whatsapp/settings/report-settings"),
    templates: path(ROOTS_DASHBOARD, "/whatsapp/whatsapp-templates"),
    createTemplate: path(
      ROOTS_DASHBOARD,
      "/whatsapp/whatsapp-templates/create-template"
    ),
    broadcast: path(ROOTS_DASHBOARD, "/whatsapp/broadcast-message"),
    broadcastList: path(ROOTS_DASHBOARD, "/whatsapp/broadcast-list"),
    broadcastJourney: {
      list: path(ROOTS_DASHBOARD, "/whatsapp/broadcast-journey"),
      createJourney: path(
        ROOTS_DASHBOARD,
        "/whatsapp/broadcast-journey/create-journey"
      ),
      // newBroadcast: path(
      //   ROOTS_DASHBOARD,
      //   "/whatsapp/broadcast-journey/new-broadcast"
      // ),
      newBroadcast: (id, journeyName) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast-journey/new-broadcast/${id}/${journeyName}`
        ),
      editBroadcast: (journeyId, journeyName, broadcastId, broadcastName) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast-journey/broadcast/${journeyId}/${journeyName}/${broadcastId}/${broadcastName}`
        ),
      initiate: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast-journey/initiate/${id}/${name}`
        ),
      edit: (id, name) =>
        path(ROOTS_DASHBOARD, `/whatsapp/broadcast-journey/edit/${id}/${name}`),
      history: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/whatsapp/broadcast-journey/initiate/history/${id}/${name}`
        ),
    },
    dialogflowIntents: path(
      ROOTS_DASHBOARD,
      "/whatsapp/dialogflow/manage-intents"
    ),
    dialogflowAnalytics: path(
      ROOTS_DASHBOARD,
      "/whatsapp/dialogflow/analytics"
    ),
    unsubscribersList: path(ROOTS_DASHBOARD, "/whatsapp/unsubscribers-list"),
    insights: path(ROOTS_DASHBOARD, "/whatsapp/insights"),
    wallet: path(ROOTS_DASHBOARD, "/whatsapp/wallet"),
  },

  googleMyBusiness: {
    root: path(ROOTS_DASHBOARD, "/google-my-business"),
    reviews: {
      root: path(ROOTS_DASHBOARD, "/google-my-business/reviews"),
      cannedResponses: path(
        ROOTS_DASHBOARD,
        "/google-my-business/reviews/canned-responses"
      ),
      locationReview: (id, name) =>
        path(ROOTS_DASHBOARD, `/google-my-business/reviews/${id}/${name}`),
      locationReviewAnalytics: (id, name) =>
        path(
          ROOTS_DASHBOARD,
          `/google-my-business/reviews/${id}/${name}/analytics`
        ),
    },
    settings: path(ROOTS_DASHBOARD, "/google-my-business/settings"),
    googleMapsApiKeys: path(
      ROOTS_DASHBOARD,
      "/google-my-business/settings/google-maps-api-keys"
    ),
    gmbSeekReviews: path(
      ROOTS_DASHBOARD,
      "/google-my-business/gmb-seek-reviews"
    ),
    locations: path(ROOTS_DASHBOARD, "/google-my-business/locations"),
    addLocation: path(ROOTS_DASHBOARD, "/google-my-business/add-location"),
    edit: (name) => path(ROOTS_DASHBOARD, `/google-my-business/${name}/edit`),
    geoGridScheduleList: path(ROOTS_DASHBOARD, "/google-my-business/geo-grid"),
    locationReport: path(
      ROOTS_DASHBOARD,
      "/google-my-business/geo-grid/location-report"
    ),
    geoGridNewReportSchedule: path(
      ROOTS_DASHBOARD,
      "/google-my-business/geo-grid/new-report-schedule"
    ),
    geoGridReportDetails: (repId) =>
      path(ROOTS_DASHBOARD, `/google-my-business/geo-grid/${repId}`),
    profileInfo: path(ROOTS_DASHBOARD, "/google-my-business/profile-info"),
    profileUpdateHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/profile-info/profile-update-history"
    ),
    addUpdate: path(ROOTS_DASHBOARD, "/google-my-business/add-update"),
    updateHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/add-update/update-history"
    ),
    uploadPhotos: path(ROOTS_DASHBOARD, "/google-my-business/upload-photos"),
    uploadHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/upload-photos/upload-history"
    ),
    LocationsList: path(
      ROOTS_DASHBOARD,
      "/google-my-business/upload-photos/locations-list"
    ),
    services: path(ROOTS_DASHBOARD, "/google-my-business/services"),
    serviceHistory: path(
      ROOTS_DASHBOARD,
      "/google-my-business/services/update-history"
    ),
    schemaInfo: path(ROOTS_DASHBOARD, "/google-my-business/schema-info"),
    generateSchema: path(
      ROOTS_DASHBOARD,
      `/google-my-business/schema-info/generate-schema`
    ),
    // googleWorkspace: path(
    //   ROOTS_DASHBOARD,
    //   "/google-my-business/google-workspace"
    // ),
    // customerInfo: path(
    //   ROOTS_DASHBOARD,
    //   "/google-my-business/google-workspace/info"
    // ),
    // editCustomerInfo: path(
    //   ROOTS_DASHBOARD,
    //   "/google-my-business/google-workspace/info/edit"
    // ),
  },

  googleAds: {
    root: path(ROOTS_DASHBOARD, "/google-ads"),
    overview: path(ROOTS_DASHBOARD, "/google-ads/overview"),
    settings: path(ROOTS_DASHBOARD, "/google-ads/settings"),
  },

  googleWorkspace: {
    root: path(ROOTS_DASHBOARD, "/google-workspace"),
    customerInfo: path(ROOTS_DASHBOARD, "/google-workspace/info"),
    editCustomerInfo: path(ROOTS_DASHBOARD, "/google-workspace/info/edit"),
  },

  assetsManager: {
    root: path(ROOTS_DASHBOARD, "/assets-manager"),
    domainSetup: path(ROOTS_DASHBOARD, "/assets-manager/domain-setup"),
    tag: path(ROOTS_DASHBOARD, "/assets-manager/url-manager/manage-tags"),
    url: path(ROOTS_DASHBOARD, "/assets-manager/url-manager"),
    media: path(ROOTS_DASHBOARD, "/assets-manager/media-manager"),
  },

  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
};
