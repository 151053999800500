import { HOST_API } from "../../../config";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Stack,
  Drawer,
  Card,
  Typography,
  Avatar,
  Divider,
  Link,
} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import IconLogo from "../../../components/IconLogo";
import Scrollbar from "../../../components/Scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import Iconify from "src/components/Iconify";
//
import navConfig from "./NavConfig";
import CollapseButton from "./CollapseButton";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // function stringAvatar(name) {
  //   return {
  //     sx: {
  //       bgcolor: "primary.lighter",
  //       color: "#2065D1",
  //       p: 3,
  //       m: 0,
  //     },
  //     children: `${name.split(" ")[0][0]}`,
  //   };
  // }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 2,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: "center" }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!isCollapse && <Logo />}
          {isCollapse && <IconLogo />}

          {isDesktop && !isCollapse && (
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          )}
        </Stack>
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} />
      <Divider sx={{ my: 1 }} />
      {isCollapse &&
        JSON.parse(localStorage.getItem("profile")).org_detail?.primary_am !==
          null && (
          <Stack alignItems={"center"}>
            <Avatar
              alt={
                JSON.parse(localStorage.getItem("profile")).org_detail
                  ?.primary_am?.user?.name
              }
              src={`${HOST_API}/lms_user/public/${
                JSON.parse(localStorage.getItem("profile")).org_detail
                  ?.primary_am?.user?.image
              }`}
            />
          </Stack>
        )}
      {!isCollapse &&
        JSON.parse(localStorage.getItem("profile")).org_detail?.primary_am !==
          null && (
          <Box sx={{ p: 2.5 }}>
            <Card sx={{ p: 2 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ mb: 2 }}
              >
                {/* <Avatar {...stringAvatar("Chethan Kumar")} /> */}
                <Avatar
                  alt={
                    JSON.parse(localStorage.getItem("profile")).org_detail
                      ?.primary_am?.user?.name
                  }
                  src={`${HOST_API}/lms_user/public/${
                    JSON.parse(localStorage.getItem("profile")).org_detail
                      ?.primary_am?.user?.image
                  }`}
                />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      width: isCollapse ? 0 : "100%",
                      opacity: isCollapse ? "0" : "1",
                      transition:
                        "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                  >
                    {
                      JSON.parse(localStorage.getItem("profile")).org_detail
                        ?.primary_am?.user?.name
                    }
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      width: isCollapse ? 0 : "100%",
                      opacity: isCollapse ? "0" : "1",
                      transition:
                        "width 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms,opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                  >
                    Relationship manager
                  </Typography>
                </Box>
              </Stack>
              <Link href="tel:+917411251394">
                <Stack direction={"row"} alignItems="center">
                  <Iconify icon={"eva:phone-call-outline"} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {
                      JSON.parse(localStorage.getItem("profile")).org_detail
                        ?.primary_am?.user?.mobile
                    }
                  </Typography>
                </Stack>
              </Link>
              <Divider sx={{ my: 1 }} />
              <Link href="mailto:chethan@emovur.com">
                <Stack direction={"row"} alignItems="center">
                  <Iconify icon={"eva:email-outline"} />
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {
                      JSON.parse(localStorage.getItem("profile")).org_detail
                        ?.primary_am?.user?.email
                    }
                  </Typography>
                </Stack>
              </Link>
            </Card>
          </Box>
        )}
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse
            ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
            : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "dashed",
              bgcolor: "background.default",
              transition: (theme) =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
