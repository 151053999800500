import { createContext, useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import axios from "src/utils/axios";
import { isValidToken } from "src/utils/jwt";

// Create context
export const BroadcastJourneyContext = createContext();

// Initial State
const initialState = {
  journeys: null,
};

// Action handlers
const handlers = {
  INITIALIZE: (state, action) => {
    const { journeys } = action.payload;
    return { ...state, journeys };
  },
  UPDATE_BROADCAST: (state, action) => {
    const { broadcasts, journeyId } = action.payload;
    const { journeys } = state;
    let updatedJourneys = [...journeys];
    updatedJourneys.forEach((journey) => {
      if (journey.journey_id === journeyId) {
        journey.broadcasts = broadcasts;
      }
    });
    return { ...state, journeys: updatedJourneys };
  },
};

// Create reducer
const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const BroadcastJourneyProvider = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "get",
          url: "/lms_campaign/api/whatsapp/journey",
          // headers: { Authorization: `Bearer ${accessToken}` },
        });
        const { journeys } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            journeys,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const createJourney = async (journeyData) => {
    try {
      const data = new FormData();
      data.append("journey_name", journeyData.journeyName);
      data.append("sample_excel", journeyData.excelFileLoc);
      data.append("receiver_column", journeyData.recipientColumn);
      data.append("allow_duplicates", journeyData.allowDuplicates ? "1" : "0");
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "post",
          url: "/lms_campaign/api/whatsapp/journey",
          // headers: { Authorization: `Bearer ${accessToken}` },
          data: data,
        });
        const { journey, journeys } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            journeys,
          },
        });
        return journey;
      }
    } catch (error) {
      throw new Error(
        Object.keys(error.errors).map((err) => error.errors[err])
      );
    }
  };

  const updateJourney = async (journeyData) => {
    try {
      const data = new FormData();
      data.append("_method", "patch");
      data.append("journey_name", journeyData.journeyName);
      data.append("sample_excel", journeyData.excelFileLoc);
      data.append("receiver_column", journeyData.recipientColumn);
      data.append("allow_duplicates", journeyData.allowDuplicates ? "1" : "0");
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "post",
          url: `/lms_campaign/api/whatsapp/journey/${journeyData.id}`,
          // headers: { Authorization: `Bearer ${accessToken}` },
          data: data,
        });
        const { journeys } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            journeys,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const deleteJourney = async (journeyId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "delete",
          url: `/lms_campaign/api/whatsapp/journey/${journeyId}`,
        });
        const { journeys } = await response.data;
        dispatch({
          type: "INITIALIZE",
          payload: {
            journeys,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const createBroadcast = async (broadcastFormData) => {
    try {
      const data = new FormData();
      if (broadcastFormData.dynamicTemplate) {
        for (const key in broadcastFormData.currDynamicValues) {
          if (
            Object.hasOwnProperty.call(broadcastFormData.currDynamicValues, key)
          ) {
            switch (key) {
              case "templateImg":
                data.append("image", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateVideo":
                data.append("video", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateDoc":
                data.append(
                  "document",
                  broadcastFormData.currDynamicValues[key]
                );
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateText":
                delete broadcastFormData.currDynamicValues[key];
                break;
              default:
                break;
            }
          }
        }
      }
      if (!broadcastFormData.dynamicTemplate) {
        for (const key in broadcastFormData.currDynamicValues) {
          if (
            Object.hasOwnProperty.call(broadcastFormData.currDynamicValues, key)
          ) {
            switch (key) {
              case "templateImg":
                data.append("image", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateVideo":
                data.append("video", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateDoc":
                data.append(
                  "document",
                  broadcastFormData.currDynamicValues[key]
                );
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateText":
                delete broadcastFormData.currDynamicValues[key];
                break;
              default:
                break;
            }
          }
        }
      }
      data.append("journey_id", broadcastFormData.journeyId);
      data.append("broadcast_name", broadcastFormData.broadcastName);
      data.append("templateId", broadcastFormData.templateId);
      data.append("schedule_type", broadcastFormData.scheduleType);
      if (broadcastFormData.scheduleType === "after") {
        data.append("after_time", broadcastFormData.scheduleValue);
        data.append("after_type", broadcastFormData.scheduleTime);
      }
      data.append(
        "values",
        JSON.stringify(broadcastFormData.currDynamicValues)
      );
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "post",
          url: `/lms_campaign/api/whatsapp/journey/broadcast`,
          // headers: { Authorization: `Bearer ${accessToken}` },
          data: data,
        });
        const { broadcasts } = await response.data;
        dispatch({
          type: "UPDATE_BROADCAST",
          payload: {
            broadcasts,
            journeyId: broadcastFormData.journeyId,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const updateBroadcast = async (broadcastFormData, broadcastId) => {
    try {
      const data = new FormData();
      if (broadcastFormData.dynamicTemplate) {
        for (const key in broadcastFormData.currDynamicValues) {
          if (
            Object.hasOwnProperty.call(broadcastFormData.currDynamicValues, key)
          ) {
            switch (key) {
              case "templateImg":
                data.append("image", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateVideo":
                data.append("video", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateDoc":
                data.append(
                  "document",
                  broadcastFormData.currDynamicValues[key]
                );
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateText":
                delete broadcastFormData.currDynamicValues[key];
                break;
              default:
                break;
            }
          }
        }
      }
      if (!broadcastFormData.dynamicTemplate) {
        for (const key in broadcastFormData.currDynamicValues) {
          if (
            Object.hasOwnProperty.call(broadcastFormData.currDynamicValues, key)
          ) {
            switch (key) {
              case "templateImg":
                data.append("image", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateVideo":
                data.append("video", broadcastFormData.currDynamicValues[key]);
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateDoc":
                data.append(
                  "document",
                  broadcastFormData.currDynamicValues[key]
                );
                delete broadcastFormData.currDynamicValues[key];
                break;
              case "templateText":
                delete broadcastFormData.currDynamicValues[key];
                break;
              default:
                break;
            }
          }
        }
      }
      data.append("journey_id", broadcastFormData.journeyId);
      data.append("broadcast_name", broadcastFormData.broadcastName);
      data.append("templateId", broadcastFormData.templateId);
      data.append("schedule_type", broadcastFormData.scheduleType);
      if (broadcastFormData.scheduleType === "after") {
        data.append("after_time", broadcastFormData.scheduleValue);
        data.append("after_type", broadcastFormData.scheduleTime);
      }
      data.append(
        "values",
        JSON.stringify(broadcastFormData.currDynamicValues)
      );
      data.append("_method", "patch");
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "post",
          url: `/lms_campaign/api/whatsapp/journey/broadcast/${broadcastId}`,
          // headers: { Authorization: `Bearer ${accessToken}` },
          data: data,
        });
        const { broadcasts } = await response.data;
        dispatch({
          type: "UPDATE_BROADCAST",
          payload: {
            broadcasts,
            journeyId: broadcastFormData.journeyId,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const deleteBroadcast = async (journeyId, broadcastId) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (isValidToken(accessToken)) {
        const response = await axios({
          method: "delete",
          url: `/lms_campaign/api/whatsapp/journey/broadcast/${broadcastId}`,
          params: { journey_id: journeyId },
        });
        const { broadcasts } = await response.data;
        dispatch({
          type: "UPDATE_BROADCAST",
          payload: {
            broadcasts,
            journeyId,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <BroadcastJourneyContext.Provider
      value={{
        journeys: state.journeys,
        createJourney,
        updateJourney,
        deleteJourney,
        createBroadcast,
        updateBroadcast,
        deleteBroadcast,
      }}
    >
      <Outlet />
    </BroadcastJourneyContext.Provider>
  );
};
